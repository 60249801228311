import _parser from "./parser";
import _signer from "./signer";
import _verify from "./verify";
import _utils from "./utils";
var exports = {};
// Copyright 2015 Joyent, Inc.
var parser = _parser;
var signer = _signer;
var verify = _verify;
var utils = _utils; ///--- API

exports = {
  parse: parser.parseRequest,
  parseRequest: parser.parseRequest,
  sign: signer.signRequest,
  signRequest: signer.signRequest,
  createSigner: signer.createSigner,
  isSigner: signer.isSigner,
  sshKeyToPEM: utils.sshKeyToPEM,
  sshKeyFingerprint: utils.fingerprint,
  pemToRsaSSHKey: utils.pemToRsaSSHKey,
  verify: verify.verifySignature,
  verifySignature: verify.verifySignature,
  verifyHMAC: verify.verifyHMAC
};
export default exports;
export const parse = exports.parse;